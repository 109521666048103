.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: -9999;

  display: grid;
  grid-template-rows: auto 1fr auto;
}

.overlay.visible {
  opacity: 1;
  pointer-events: all;
  z-index: 9999;
}

.ant-collapse {
  overflow-y: scroll;
  width: 100%;
}

::-webkit-scrollbar {
  width: 0;
}

.overlay ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.overlay li::before {
  content: '→';
  color: #ff6666;
  display: inline-block;
  width: 1em;
  /* margin-left: -1em; */
  margin-right: 0.5rem;
}

.overlay .header {
  display: grid;
  grid-template-columns: 1fr auto auto;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 1rem 2rem;
}

.overlay .header button {
  width: min-content;
  white-space: nowrap;
}

.overlay .quality {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.overlay .quality span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.overlay .close {
  display: flex;
  opacity: 0.7;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.overlay .close:hover {
  opacity: 1;
}

.overlay > div {
  padding: 1rem;
}

.overlay .panel-header {
  font-family: 'Futura PT', system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: uppercase;
}

.ant-collapse-header {
  align-items: center !important;
}

/* Credits */
.credits {
  justify-self: center;
  text-align: center;
}

/* Informations */
.interface.informations,
.interface.instructions {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 1rem;
  line-height: 1.5rem;
}

/* Instructions */
.interface.instructions .title {
  grid-column: span 2;
}

.interface.instructions .title:not(span:nth-child(1)) {
  margin-top: 1rem;
}

/* Caption */
.caption-group {
  display: flex;
  align-items: center;
  gap: 1rem;
  line-height: 1.7;
  cursor: pointer;
}

.caption-group > span {
  display: flex;
  align-items: center;
}
