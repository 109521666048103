@import './fonts/futura/stylesheet.css';

.interface {
  color: #fff;
  font-family: 'Futura PT', system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 1.2rem;
  z-index: 9990;
  opacity: 1;
  pointer-events: all;

  transition: all 0.3s ease;
}

.interface.hidden {
  opacity: 0;
  pointer-events: none;
  z-index: -9999;
}

.interface .title {
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}
