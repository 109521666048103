html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  background: #131313;
}

canvas {
  touch-action: none;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
}

a {
  color: #ff6666;
  text-decoration: none;
  transition: none;
}

a:hover {
  color: #ff9999;
  text-decoration: underline;
}

p {
  line-height: 1.4;
  margin: 0;
}

button {
  font-family: 'Futura PT', system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  text-transform: uppercase;
  color: #fff;
  background: transparent;
  font-size: 1rem;
  padding: 0.3rem 1rem;
  border: none;
  border-radius: 0.2rem;
  outline: none;
  opacity: 0.8;
  cursor: pointer;

  transition: opacity 0.2s ease-in-out;
}

button:hover {
  opacity: 1;
}

button.special {
  background: rgba(255, 102, 102, 0.7);
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  letter-spacing: 0.1rem;
  opacity: 1;
  cursor: pointer;
  transition: all 0.3s ease;
}

button.special:hover {
  background: rgba(255, 102, 102, 0.9);
}

button.active {
  background: rgba(255, 102, 102, 0.5);
  opacity: 1;
}

blockquote {
  margin: 0;
  padding: 0;
  border-left: 0.1rem solid #ff6666;
  font-style: italic;
  font-size: 1.2rem;
  line-height: 1.4;
  padding-left: 1rem;
}

.warning {
  color: #ff9f1c;
}

.info {
  color: #ff6666;
  cursor: pointer;
}

.error {
  color: #ff6666;
  font-weight: 700;
}

.cursor {
  cursor: pointer;
}
